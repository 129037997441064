<template>
  <CreatePriceComp />
</template>
<script>
export default {
  name: "CreatePrice",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Pricing", path:"/bills/prices", isActive: false}, {text:"Create", path:"", isActive: true}] 
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  components: {
    CreatePriceComp: () => import("../../../src/components/pricing/CreatePriceComp"),
  },
};
</script>